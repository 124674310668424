import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { BiChevronRight, BiHomeAlt, BiBarChartAlt2, BiBell, BiLogOut } from "react-icons/bi";
import { LiaCertificateSolid } from "react-icons/lia";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Usercontext } from "../../Store/UserContext";
import { IoPersonCircleOutline } from "react-icons/io5";

const SuperAdminSidebar = () => {
  const [isClosed, setIsClosed] = useState(false); // Sidebar open/close state
  const [isDarkMode, setIsDarkMode] = useState(false); // Dark mode toggle
  const navigate = useNavigate(); // For navigation
  const location = useLocation(); // Get the current location
  const { user, loading, error, setUser } = useContext(Usercontext);
  

  // Toggle Sidebar state (open/close)
  const toggleSidebar = () => {
    setIsClosed(!isClosed);
  };

  // Loading and error handling
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Get the current path for active link styling
  const currentPath = location.pathname;

  // Function to check if a link is active
  const isActiveLink = (path) => {
    return currentPath === path ;
  };

  return (
    <div className={`${isDarkMode ? "dark" : ""} flex`}>
      <nav className={`bg-[#075985] mt-1 h-screen  text-white dark:bg-ocean-800 min-h-screen transition-all duration-300 ${isClosed ? "w-20" : "w-64"} p-4`}>
        <header className="relative flex flex-col items-center">
          {/* Toggle button moved here */}
          <button onClick={toggleSidebar} className="bg-[#2e83b1] text-white w-10 p-2 rounded-full absolute top-5 right-0 z-10 ">
            <BiChevronRight className={`transform transition-transform duration-300 ${isClosed ? "" : "rotate-180"}`} />
          </button>

          {/* User name and Edit button */}
          <div className={`mt-12  flex flex-col items-center ${isClosed ? "hidden" : ""}`}>
            {user && (
              <>
               <IoPersonCircleOutline className="w-14 h-14" />
                <span className="text-lg text-ocean-500 dark:text-ocean-400">{user.name}</span>
               </>
            )}
          </div>
        </header>

        <div className={`mt-10 ${isClosed ? "mt-14" : "mt-10"}`}>
          <ul className="space-y-4">
            <li>
              <Link to="/admin" className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${isActiveLink('/admin') ? 'bg-sky-900' : ''}`}>
                <BiHomeAlt className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && <span className="text-md font-medium text-ocean-800 dark:text-ocean-100">Dashboard</span>}
              </Link>
            </li>
            <li>
              <Link to="/admin/department" className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${isActiveLink('/admin/department') ? 'bg-sky-900' : ''}`}>
                <BiBarChartAlt2 className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && <span className="text-md font-medium text-ocean-800 dark:text-ocean-100">Manage Departments</span>}
              </Link>
            </li>
            <li>
              <Link to="/admin/ManageCertificates" className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${isActiveLink('/admin/ManageCertificates') ? 'bg-sky-900' : ''}`}>
              <LiaCertificateSolid className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && <span className="text-md font-medium text-ocean-800 dark:text-ocean-100">Manage Certificates</span>}
              </Link>
            </li>
            

          
          </ul>
        </div>
      </nav>
      {/* Main content area adjusted based on sidebar width, margin applied only for large screens */}
      <div className={`flex-1 transition-all duration-300 ${isClosed ? "lg:ml-18" : "lg:ml-50"}`}>
        {/* Render your main content here, like the AppliedCertificates component */}
      </div>
    </div>
  );
};

export default SuperAdminSidebar;
