import React, { useContext, useState } from "react";
import { Usercontext } from "../Store/UserContext";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import UserNavbar from "../Component/Navbar/UserNavbar";
import Footer from "../Component/Footer/Footer";
import { useNavigate } from "react-router-dom";

const DeleteMyAccount = () => {
  const { user } = useContext(Usercontext);
  const [fullName, setFullName] = useState(user.name || "");
  const [mobileNumber, setMobileNumber] = useState(user.phoneNo);
  const [emailId, setEmailId] = useState(user.email);
  const navigate =  useNavigate()

  const handleSendRequest = async () => {
    try {
      const response = await axios.post(
        "http://localhost:8080/app/user/send-request",
        {
          fullName,
          emailId,
        }
      );

      toast.success(response.data.message || "Request sent successfully!");
    } catch (error) {
      console.error("Error sending request:", error);
      toast.error("Failed to send request. Please try again.");
    }
  };

  const handleNavigate = () => {
    navigate('/profile')
  };
  return (
    <>
    <UserNavbar/>
      {" "}
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex justify-center ">
        <div className="bg-white border border-black mt-2 mb-2 p-8 rounded-xl shadow-2xl w-full max-w-lg">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">
            Delete Account Request
          </h3>
          <p className="text-sm text-gray-600 mb-6">
            Are you sure you want to delete your account? This action cannot be
            undone.
          </p>

          <div className="bg-gray-50 p-4 rounded-lg mb-6">
            <div className="mb-3">
              <label className="text-sm text-gray-600 font-bold">
                Full Name:
              </label>
              <p className="text-lg text-gray-800 mt-1">{fullName}</p>
            </div>
            <div className="mb-3">
              <label className="text-sm text-gray-600 font-bold">
                Email ID:
              </label>
              <p className="text-lg text-gray-800 mt-1">{emailId}</p>
            </div>
          </div>

          <div className="mb-6">
            <label className="text-sm text-gray-600 font-bold">
              Reason for Deletion:
            </label>
            <textarea
              className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-400"
              placeholder="Enter your reason"
              rows="4"
            />
          </div>

          <div className="flex justify-end gap-3">
            <button  onClick={handleNavigate} className="px-5 py-2 text-gray-700 bg-gray-200 rounded-lg shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400">
              Cancel
            </button>
            <button
              onClick={handleSendRequest}
              className="px-5 py-2 text-white bg-red-600 rounded-lg shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400"
            >
              Send Request
            </button>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default DeleteMyAccount;
