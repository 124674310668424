import React from 'react'
import Navbar from '../Component/Navbar/Navbar'
import Footer from '../Component/Footer/Footer'

const RefundPolicy = () => {
 
    return (
        <div className="min-h-screen bg-gray-100 ">
            <Navbar/>
            <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-md p-8 p-8 mt-10 mb-10">
                <h1 className="text-3xl font-bold text-gray-800 mb-6">
                    Refund and Cancellation Policy
                </h1>
                <h1 className="text-xl font-semibold text-gray-800 mb-2">30-Day Satisfaction Guarantee
                </h1>

                <p className="text-gray-700 mb-6">
                    If our services do not meet your expectations, you have 30 days from the date of payment to request a refund. After this period,
                    refunds will be processed as service credits, which can be utilized for future services.
                    We understand that you may have questions about this Privacy Commitment, on how we process
                    or handle your Customer Information, or may otherwise want to understand these aspects.
                    We welcome you to reach out to us with your queries, grievances, feedback, and comments
                    by contacting our grievance officer, whose details are provided below

                </p>
                <section className="mb-6">
                    <h2 className="text-xl font-semibold text-gray-800 mb-2">
                        Cancellation Policy
                    </h2>
                    <p className="text-gray-700">
                        Before any refund is processed, we strive to fulfill the service to the best of our abilities.
                        In the event of dissatisfaction, a cancellation fee of 20%, will be deducted. If there is a change of service,
                        the cancellation fee will not apply.Refunds will be subject to a minimum 20% cancellation fee.
                        Additionally, any fees incurred by Yahshua Software Limited in completing the service will be deducted at the company's discretion.
                        The refund amount will not exceed 80% of the total fee paid by the client.

                    </p>

                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold text-gray-800 mb-2">
                        Change of Service

                    </h2>
                    <p className="text-gray-700">
                        Clients can request a change of service after 30 days of purchase.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold text-gray-800 mb-2">
                        Requesting a Refund

                    </h2>
                    <p className="text-gray-700">
                        To initiate a refund request, log in to your YSL Web account and select "LIVE CHAT" to begin the process. Additionally, send an email to <b className='text-black'>yahshuaenterprises@gmail.com</b><br></br>
                        with transaction details.
                        Refunds are typically processed within 3-5 weeks of the request, provided all necessary information is provided.

                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold text-gray-800 mb-2">
                        Terms & Conditions applicable to our Incorporation, Startup and Registration services

                    </h2>
                    <p className="text-gray-700">
                        a. For bank account or payment services, your name, phone number, and email will be shared with a bank of your choice, with your consent.
                        Banks reserve the right to open or decline your request based on their policy.<br></br>
                        b. If you wish to register a Partnership Firm with the Registrar of Firms of the respective states, additional charges as applicable, will be payable and conveyed upfront.<br></br>
                        c. Up to 4 name options can be provided in one RUN name approval request.<br></br>
                        d. Authorised capital is the number of shares a company can issue at any time, which can be increased further. Paid-up capital is the amount shareholders invest, which can be even Rs.2.<br></br>
                        e. Statutory Auditor fees are payable on actuals directly to the Independent Auditor appointed by the Board of Directors. All companies must appoint an Independent Statutory Auditor of their choice within 30 days of incorporation.<br></br>
                        f. YEL Seva provides 2 DSC Hyper Token & support for KYC and DSC shipping with each incorporation. As per CCA Guidelines as on 15th July, 2024, the DSC Application will have to make direct payment to the Certifying Authority for certificate and KYC.<br></br>
                        g. Additional authorised capital can be purchased if required at the time of incorporation. The price for additional authorised capital can be provided upon request.<br></br>
                        h. Clients are responsible for paying stamp duty, which varies depending on the state of incorporation and the company's registered capital. Below is a detailed list of stamp duty charges based on State and capital range:
                    </p>
                </section>
            </div>
            <Footer/>
        </div>

  
  )
}

export default RefundPolicy