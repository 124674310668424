import React, { useState, useEffect } from "react";
import SummaryApi from "../../common/Apis";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { FaDownload } from "react-icons/fa"; // Import the download icon
import { TailSpin } from "react-loader-spinner"; // Import TailSpin

import JSZip from "jszip";
import { saveAs } from "file-saver";

const AssignedTaskPage = () => {
  const [assignedCertificates, setAssignedCertificates] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [updating, setUpdating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [revertCertificateId, setRevertCertificateId] = useState(null);
  const [issueText, setIssueText] = useState("");
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadCertificateId, setUploadCertificateId] = useState(null);

  const documentOptions = [
    { value: "ID Proof", label: "ID Proof" },
    { value: "Address Proof", label: "Address Proof" },
    { value: "Birth Certificate", label: "Birth Certificate" },
    { value: "Income Proof", label: "Income Proof" },
  ];

  const statusOptions = ["pending", 'processing', 'approved', 'reverted'];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken._id;

      const fetchAssignedData = async () => {
        try {
          const url = `${SummaryApi.assignedCertificates.url}/${userId}`;
          const response = await axios.get(url);
          setAssignedCertificates(response.data.assignedCertificates || []);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchAssignedData();
    } else {
      setError("No token found, please login.");
      setLoading(false);
    }
  }, []);

  const filteredCertificates = assignedCertificates.filter((certificate) => {
    const certificateName = certificate.certificateName || "";
    return certificateName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleStatusChange = async (certificateId, newStatus) => {
    const url = `${SummaryApi.CertificatesStatusHandle.url}/${certificateId}/status`;
    setUpdating(true);

    try {
      const response = await axios.put(url, { status: newStatus });

      if (response.status === 200) {
        setAssignedCertificates((prevCertificates) =>
          prevCertificates.map((cert) =>
            cert._id === certificateId ? { ...cert, status: newStatus } : cert
          )
        );
        console.log("Status updated successfully!");
      }
    } catch (error) {
      console.error("Error updating certificate status:", error);
    } finally {
      setUpdating(false);
    }
  };

  const handleRevertClick = (certificateId) => {
    setRevertCertificateId(certificateId);
    setIsModalOpen(true);
  };

  const handleRevertSubmit = async () => {
    if (!issueText.trim()) {
      alert("Please enter a reason for the revert.");
      return;
    }

    try {
      const url = `${SummaryApi.CertificatesRevertHandle.url}/${revertCertificateId}`;
      console.log("Submitting revert with issue:", issueText);

      const response = await axios.put(url, {
        revertReason: issueText,
        selectedDocuments,
      });

      if (response.status === 200) {
        console.log("Revert reason submitted successfully!");
        toast.success("Revert reason submitted successfully!");
        setIssueText("");
        setSelectedDocuments([]);
      }
    } catch (error) {
      console.error("Error reverting the certificate:", error);
      toast.error("Error submitting revert reason.");
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleDocumentChange = (selectedOptions) => {
    setSelectedDocuments(selectedOptions || []);
  };

  const handleDownloadAll = async (documents, certificateId) => {
    const zip = new JSZip();
    try {
      //
      const filename = await axios.get(
        `${SummaryApi.getUserNameByCertificateId.url}/${certificateId}`
      );

      const zipfilename = filename.data.name;
      for (const doc of documents) {
        const response = await axios.get(doc.path, {
          responseType: "blob",
        });

        // Add each file to the ZIP folder with its filename
        zip.file(doc.filename, response.data);
      }
      // Generate the ZIP file
      const content = await zip.generateAsync({ type: "blob" });

      // Trigger the download of the ZIP file
      saveAs(content, `${zipfilename}_documents.zip`);

      toast.success("All documents downloaded as a ZIP folder!");
    } catch (error) {
      console.error("Error downloading documents:", error);
      toast.error("Error downloading documents.");
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100 absolute inset-0 z-50">
        <TailSpin
          visible={true}
          height="80"
          width="100"
          color="#0f0f0f" // Black color
          ariaLabel="tail-spin-loading"
          radius="1"
          animationDuration="0.85"
        />
      </div>
    );
  }
  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // Handle file input change
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]); // Support single file upload
  };

  // Open the upload modal
  const handleUploadClick = (certificateId) => {
    setUploadCertificateId(certificateId);
    setIsUploadModalOpen(true);
  };

  // Handle file upload submission
  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("files", selectedFile);
    // formData.append("certificateId", uploadCertificateId);

    try {
      setUploading(true);

      const response = await axios.post(`${SummaryApi.uploadeCompletedCertificate.url}/${uploadCertificateId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        toast.success("File uploaded successfully!");
        setSelectedFile(null);
        setUploadCertificateId(null);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("File upload failed. Please try again.");
    } finally {
      setUploading(false);
      setIsUploadModalOpen(false);
    }
  };

  return (
    <div className="container mx-auto mt-4">
      <ToastContainer />
      <h1 className="text-3xl text-center justify-center font-semibold text-orange-500 pb-4">
        Assigned Certificates
      </h1>

      <input
        type="text"
        placeholder="Search by certificate name"
        className="mb-4 px-4 py-2 border-gray-600 border rounded w-full"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <table className="min-w-full border-separate bg-white border-spacing-0 border rounded-lg overflow-hidden border-gray-900">
        <thead className="border-b">
          <tr className="rounded-lg">
            <th className="px-4 py-2 border bg-[#075985] text-white rounded-tl-lg">
              Certificate ID
            </th>
            <th className="px-4 py-2 border bg-[#075985] text-white">
              Certificate Name
            </th>
            <th className="px-4 py-2 border bg-[#075985] text-white">
              Uploaded Documents
            </th>
            {/* <th className="px-4 py-2 border bg-[#075985] text-white">
        Payment Status
      </th> */}
            <th className="px-4 py-2 border bg-[#075985] text-white">Status</th>
            <th className="px-4 py-2 border bg-[#075985] text-white">
              Application Date
            </th>
            <th className="px-4 py-2 border  bg-[#075985] text-white rounded-tr-lg">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredCertificates.length > 0 ? (
            filteredCertificates.map((certificate) => (
              <tr key={certificate._id}>
                <td className=" bg-white border px-4 py-2">
                  {certificate._id}
                </td>
                <td className=" bg-white border px-4 py-2">
                  {certificate.certificateName}
                </td>
                <td className=" bg-white border px-4 py-2">
                  <div className="flex flex-col">
                    {certificate.uploadedDocuments.map((doc, idx) => (
                      <div key={idx}>
                        {idx + 1}.{" "}
                        <a
                          href={doc.path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {doc.filename}
                        </a>
                      </div>
                    ))}

                    {certificate.uploadedDocuments.length > 0 && (
                      <button
                        className="mt-2 flex items-center text-blue-500"
                        onClick={() =>
                          handleDownloadAll(
                            certificate.uploadedDocuments,
                            certificate._id
                          )
                        }
                      >
                        <FaDownload className="mr-2" /> Download All
                      </button>
                    )}
                  </div>
                </td>
                {/* <td className="bg-white border px-4 py-2">
                  {certificate.paymentStatus}
                </td> */}
                <td className=" bg-white border px-4 py-2">
                  <select
                    value={certificate.status}
                    onChange={(e) =>
                      handleStatusChange(certificate._id, e.target.value)
                    }
                    className="border p-2 rounded-md"
                    disabled={updating}
                  >
                    {statusOptions.map((status) => (
                      <option key={status} value={status}>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </option>
                    ))}
                  </select>
                </td>
                <td className=" bg-white border px-4 py-2">
                  {new Date(certificate.applicationDate).toLocaleDateString()}
                </td>
                <td className=" bg-white border  h-full flex flex-col gap-1 px-4 py-2">
                  <button
                    className="bg-red-500 text-white px-4 py-1 rounded"
                    onClick={() => handleRevertClick(certificate._id)}
                  >
                    Revert
                  </button>
                  {/* <button
                    className="bg-blue-700 text-white px-4 py-1 rounded"
                    onClick={() => handleUploadClick(certificate._id)}
                  >
                    Upload
                  </button> */}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="border px-4 py-2 text-center">
                No certificates found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-lg w-full max-w-md mx-4 sm:w-1/2 md:w-1/3">
            <h2 className="text-xl font-semibold mb-4">Revert Application</h2>
            <textarea
              placeholder="Describe the issue with this application"
              value={issueText}
              onChange={(e) => setIssueText(e.target.value)}
              className="w-full border p-2 rounded-md mb-4"
            />
            <Select
              isMulti
              options={documentOptions}
              value={selectedDocuments}
              onChange={handleDocumentChange}
              placeholder="Select affected documents"
              className="mb-4"
            />
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleRevertSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {isUploadModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-lg w-full max-w-md mx-4 sm:w-1/2 md:w-1/3">
            <h2 className="text-xl font-semibold mb-4">Upload File</h2>
            <input
              type="file"
              onChange={handleFileChange}
              className="w-full border p-2 rounded-md mb-4"
            />
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => setIsUploadModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleFileUpload}
                disabled={uploading}
              >
                {uploading ? "Uploading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignedTaskPage;
