import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SummaryApi from "../../common/Apis";
import { ColorRing } from "react-loader-spinner";
import { TailSpin } from 'react-loader-spinner'
import { RotatingLines } from "react-loader-spinner";

const RationCard = () => {
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true); // Manage loading state

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await fetch(SummaryApi.addDepartment.url); // Replace with your API endpoint
        const data = await response.json();
        setDepartments(data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
      finally {
        setLoading(false); // Stop loading when data is fetched
      }
    };

    fetchDepartments();
  }, []);
  console.log("this is the array or depatment", departments);

  const handlenavigate = (certificatename) => {
    navigate("/service", { state: { certificatename } });
  };
  if (loading) {
    return (
      <div className="grid justify-center items-center h-screen   ">
        <TailSpin
          visible={true}
          height="80"
          width="100"
          color="#0f0f0f" // Black color
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          animationDuration="0.75"
          wrapperClass=""
        />

      </div>
    );
  }

  return (
    <div className="flex flex-col w-full items-center justify-center p-4">
      <div className="w-full md:max-w-4xl p-4">
        <h2 className="text-3xl flex justify-center font-bold text-orange-500 border-b-2 border-gray-300 pb-2 mb-6">
          Application for Ration Card Services
        </h2>
        <div className="gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mx-auto mt-6">
          {departments.map(
            (department) =>
              department.name === "Ration Services" &&
              (department.certificates && department.certificates.length > 0 ? (
                department.certificates.map((certificate, index) => (
                  <div
                    key={index}
                    className="border w-full max-w-xs border-gray-600 rounded-lg shadow-sm mb-4 flex flex-col justify-between"
                  >
                    <div className="bg-gradient-to-r  from-blue-500 to-teal-400  h-1 rounded-t-lg"></div>
                    <div className="p-4 flex flex-col justify-between h-full">
                      <h2 className="text-lg font-semibold text-gray-800">
                        {certificate.name}
                        <span className="ml-1">›</span>
                      </h2>
                      <button
                        onClick={() => handlenavigate(certificate.name)}
                        className="mt-4 self-start text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>No certificates available for this department.</p>
              ))
          )}
        </div>
      </div>
    </div>
  );
};

export default RationCard;