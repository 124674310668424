import React from "react";
import Footer from "../Component/Footer/Footer";
import { Outlet } from "react-router-dom";
import Sidebar from "../Component/Dashboard/Sidebar";
import UserNavbar from "../Component/Navbar/UserNavbar";

const Dashbord = () => {
  return (
    <>
      <div className="flex flex-col h-screen">
        <div className="fixed top-0 left-0 w-full z-10">
          <UserNavbar />
        </div>
        <div className="flex flex-col mt-16 gap-2">
          <div className="flex h-screen">
            <div className=" left-0  ">
              <Sidebar />
            </div>
            <div className="  bg-gray-200   p-5 w-full overflow-auto" >
              <Outlet />
            </div>
          </div>
        </div>
        <footer className="bg-gray-200 py-2  w-full   h-full bottom-0  ">
          <Footer />
        </footer>
      </div>
    </>
  );
};

export default Dashbord;