import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FaTrash, FaEdit } from "react-icons/fa";
import SummaryApi from "../../common/Apis"; // Adjust the import based on your file structure
import { TailSpin } from 'react-loader-spinner'; // Import TailSpin


const AvailableCertificates = () => {
  const [certificates, setCertificates] = useState([]);
  const [filteredCertificates, setFilteredCertificates] = useState([]);
  const [editCertificate, setEditCertificate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false); // New state for delete confirmation popup
  const [CertificatesToDelete, setCertificatesToDelete] = useState(null); // New state to store the user to delete

  const handleDelete = async () => {
    // url ='http://localhost:8080/app/dept/api/deleteCertificate'
    try {
      const response = await axios.post(SummaryApi.deleteCertificate.url, {
        certificateId: CertificatesToDelete._id, // Pass the certificate _id
      });

      if (response.status === 200) {
        toast.success('Certificate deleted successfully');
        alert('Certificate deleted successfully')
        // Perform any additional actions after deletion, like refreshing the list
        closeDeleteConfirmPopup(); // Close the popup
        
      } else {
        toast.error('Failed to delete the certificate');
        alert('Failed to delete the certificate');
      }
    } catch (error) {
      console.error('Error deleting certificate:', error);
      toast.error('An error occurred while deleting the certificate');
      alert('An error occurred while deleting the certificate');
    }
  };


  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const response = await axios.get(SummaryApi.addDepartment.url);
        setCertificates(response.data);
        setFilteredCertificates(response.data);
      } catch (error) {
        console.error("Error fetching certificates:", error);
        setError("Failed to fetch certificates.");
      } finally {
        setLoading(false);
      }
    };
    fetchCertificates();
  }, [handleDelete]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (value === "") {
      setFilteredCertificates(certificates);
    } else {
      const filtered = certificates
        .map((department) => {
          const matchingCertificates = department.certificates.filter(
            (certificate) => certificate.name.toLowerCase().includes(value)
          );
          if (matchingCertificates.length > 0) {
            return { ...department, certificates: matchingCertificates };
          } else {
            return null;
          }
        })
        .filter((department) => department !== null);
      setFilteredCertificates(filtered);
    }
  };

  const openEditPopup = (certificate, departmentName) => {
    setEditCertificate({ ...certificate, departmentName });
    setShowEditPopup(true);
  };

  const closeEditPopup = () => {
    setEditCertificate(null);
    setShowEditPopup(false);
  };


  // Open delete confirmation popup
  const openDeleteConfirmPopup = (user) => {
    setCertificatesToDelete(user);
    setShowDeleteConfirmPopup(true);
  };

  // Close delete confirmation popup
  const closeDeleteConfirmPopup = () => {
    setCertificatesToDelete(null);
    setShowDeleteConfirmPopup(false);
  };

  const handleSaveChanges = async () => {
    try {
      const { departmentName, name, description, proofOfIdentity, proofOfAddress, amount } = editCertificate;
      const updatedDetails = {
        name,
        description,
        proofOfIdentity,
        proofOfAddress,
        amount,
      };

      await axios.put(SummaryApi.updateCertificate.url, {
        departmentName,
        certificateName: name,
        updatedDetails,
      });

      toast.success("Certificate updated successfully!");
      setShowEditPopup(false);

      // Refresh the certificates list
      const response = await axios.get(SummaryApi.addDepartment.url);
      setCertificates(response.data);
      setFilteredCertificates(response.data);
    } catch (error) {
      console.error("Error updating certificate:", error);
      toast.error("Failed to update certificate.");
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100 absolute inset-0 z-50">
        <TailSpin
          visible={true}
          height="80"
          width="100"
          color="#0f0f0f" // Black color
          ariaLabel="tail-spin-loading"
          radius="1"
          animationDuration="0.85"
        />
      </div>
    );
  }
  if (error) return <div>Error: {error}</div>;


  return (
    <div className="p-6">
      <h2 className="text-3xl font-bold mb-4 pt-5 text-orange-500">Available Certificates  </h2>
      <input
        type="text"
        placeholder="Search certificates..."
        value={searchTerm}
        onChange={handleSearch}
        className="mb-4 p-2 border border-gray-600 rounded w-full"
      />

<table className="min-w-full border-separate border-spacing-0 border  rounded-b rounded-lg border-gray-900 overflow-hidden">
<thead>
          <tr>
            <th className="border border-gray-600 px-4 py-2 bg-[#075985] text-white rounded-tl-lg">Department Name</th>
            <th className="border border-gray-600 px-4 py-2 bg-[#075985] text-white">Certificate Name</th>
            <th className="border border-gray-600 px-4 py-2 bg-[#075985] text-white">Price</th>
            <th className="border border-gray-600 px-4 py-2 bg-[#075985] text-white rounded-tr-lg">
            Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCertificates.length > 0 ? (
            filteredCertificates.map((department) => (
              <React.Fragment key={department.id}>
                {department.certificates.map((certificate, index) => (
                  <tr key={index}>
                    <td className="border bg-white border-gray-600 px-4 py-2">
                      {index === 0 ? department.name : ""}
                    </td>
                    <td className="border bg-white  border-gray-600 px-4 py-2">{certificate.name}</td>
                    <td className="border bg-white border-gray-600 px-4 py-2">{certificate.amount}</td>
                    <td className="border bg-white border-gray-600 p-2">
                      <button
                        onClick={() => openEditPopup(certificate, department.name)}
                        className="text-blue-600 hover:underline mr-2"
                      >
                        <FaEdit />
                      </button>
                      <button
                  onClick={() => openDeleteConfirmPopup(certificate)}
                  className="text-red-600 hover:underline"
                >
                  <FaTrash />
                </button>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td className="border px-4 py-2" colSpan="4">
                No certificates found for "{searchTerm}"
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {showEditPopup && editCertificate && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 pt-2 mt-24 rounded shadow-md w-[700px] max-h-[80vh] overflow-y-auto">
            <h2 className="text-lg font-semibold mb-3">Edit Certificate Details</h2>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-1">Certificate Name:</label>
              <input
                type="text"
                value={editCertificate.name}
                onChange={(e) => setEditCertificate({ ...editCertificate, name: e.target.value })}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 font-semibold mb-1">Description:</label>
              <input
                type="text"
                value={editCertificate.description || ""}
                onChange={(e) =>
                  setEditCertificate({ ...editCertificate, description: e.target.value })
                }
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 font-semibold mb-1">Proof of Identity:</label>
              <div className="flex flex-wrap gap-2">
                {editCertificate.proofOfIdentity?.map((doc, idx) => (
                  <div key={idx} className="bg-gray-200 p-1 rounded flex items-center">
                    <span>{doc}</span>
                    <button
                      onClick={() => {
                        const updatedList = editCertificate.proofOfIdentity.filter((_, i) => i !== idx);
                        setEditCertificate({ ...editCertificate, proofOfIdentity: updatedList });
                      }}
                      className="ml-2 text-red-500 hover:text-red-700"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
              <select
                onChange={(e) => {
                  const selectedDoc = e.target.value;
                  if (selectedDoc && !editCertificate.proofOfIdentity.includes(selectedDoc)) {
                    setEditCertificate({
                      ...editCertificate,
                      proofOfIdentity: [...editCertificate.proofOfIdentity, selectedDoc],
                    });
                  }
                }}
                className="w-full p-2 mt-2 border rounded"
              >
                <option value="">Select document to add</option>
                <option value="Passport">Passport</option>
                <option value="Driver's License">Driver's License</option>
                <option value="National ID">National ID</option>
              </select>
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 font-semibold mb-1">Proof of Address:</label>
              <div className="flex flex-wrap gap-2">
                {editCertificate.proofOfAddress?.map((doc, idx) => (
                  <div key={idx} className="bg-gray-200 p-1 rounded flex items-center">
                    <span>{doc}</span>
                    <button
                      onClick={() => {
                        const updatedList = editCertificate.proofOfAddress.filter((_, i) => i !== idx);
                        setEditCertificate({ ...editCertificate, proofOfAddress: updatedList });
                      }}
                      className="ml-2 text-red-500 hover:text-red-700"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
              <select
                onChange={(e) => {
                  const selectedDoc = e.target.value;
                  if (selectedDoc && !editCertificate.proofOfAddress.includes(selectedDoc)) {
                    setEditCertificate({
                      ...editCertificate,
                      proofOfAddress: [...editCertificate.proofOfAddress, selectedDoc],
                    });
                  }
                }}
                className="w-full p-2 mt-2 border rounded"
              >
                <option value="">Select document to add</option>
                <option value="Utility Bill">Utility Bill</option>
                <option value="Lease Agreement">Lease Agreement</option>
                <option value="Bank Statement">Bank Statement</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-1">Amount:</label>
              <input
                type="number"
                value={editCertificate.amount || ""}
                onChange={(e) =>
                  setEditCertificate({ ...editCertificate, amount: parseInt(e.target.value) })
                }
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleSaveChanges}
                className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
              >
                Save Changes
              </button>
              <button
                onClick={closeEditPopup}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

       {/* Delete Confirmation Popup */}
       {showDeleteConfirmPopup && CertificatesToDelete && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md w-96">
            <h2 className="text-lg font-semibold mb-4">
              Are you sure you want to delete {CertificatesToDelete.name}?
            </h2>
            <div className="flex justify-end">
              <button
                onClick={closeDeleteConfirmPopup}
                className="text-white bg-gray-600 hover:bg-gray-800 px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button  onClick={handleDelete} className="text-white bg-red-600 hover:bg-red-800 px-4 py-2 rounded">
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableCertificates;
