import React from 'react';
import Navbar from '../Component/Navbar/Navbar';
import Footer from '../Component/Footer/Footer';

const TermsAndCondition = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-md p-8 mt-10 mb-10">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">TERMS & CONDITIONS</h1>

        <p className="text-xl font-semibold text-gray-800 mb-2">
          YEL Seva is a registered Trust & Corporate Services Provider. As a client availing YEL Seva services, you agree to the following terms and conditions:
        </p>

        <p className="text-gray-700 mb-6">
          Goods and Services Tax (GST) is applicable to our fees. A GST e-Invoice and Input Tax Credit will be provided if you provided GSTIN at time of payment. It is the clients responsibility to ensure GSTIN is provided at the time of payment to receive GST Input Tax Credit.
          Any additional government fees associated with incorporation or license or trademark services, where applicable, will be notified upfront in the invoice, collected and subsequently remitted to the respective governmental authority.
        </p>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">1) Terms & Conditions that govern the usage of IndiaFilings.com</h2>
          <p className="text-gray-700">
            Welcome to yelseva.com. This website is owned and operated by Yahshua Enterprises, Shop No.1, Opposite Rajmudra Petrol Pump, Marunji Road, Marunji Gaon, Hinjewadi, Pune. By visiting our website and accessing the information, resources, services, products, and tools we provide, you understand and agree to accept and adhere to the following terms and conditions as stated in this policy (hereafter referred to as 'User Agreement'), along with the terms and conditions as stated in our Privacy Policy (please refer to the Privacy Policy section below for more information).
          </p>

          <p className="text-gray-700">
            We reserve the right to change this User Agreement from time to time without notice. You acknowledge and agree that it is your responsibility to review this User Agreement periodically to familiarize yourself with any modifications. Your continued use of this site after such modifications will constitute acknowledgment and agreement of the modified terms and conditions.
          </p>

          <h3 className="text-lg font-semibold text-gray-800 mt-4">A. RESPONSIBLE USE AND CONDUCT</h3>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              In order to access our Resources, you may be required to provide certain information about yourself (such as identification, email, phone number, contact details, etc.) as part of the registration process, or as part of your ability to use the Resources. You agree that any information you provide will always be accurate, correct, and up to date.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Resources. Accordingly, you are responsible for all activities that occur under your accounts. Yahshua Enterprises service obligation is considered complete once access to our platform is granted. All subsequent responsibilities and the final outcome of the service are solely the client's responsibility.
            </li>
            <li>
              Accessing (or attempting to access) any of our Resources by any means other than through the means we provide, is strictly prohibited. You specifically agree not to access (or attempt to access) any of our Resources through any automated, unethical or unconventional means.
            </li>
            <li>
              Engaging in any activity that disrupts or interferes with our Resources, including the servers and/or networks to which our Resources are located or connected, is strictly prohibited.
            </li>
            <li>
              Attempting to copy, duplicate, reproduce, sell, trade, or resell our resources is strictly prohibited.
            </li>
            <li>
              You are solely responsible for any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liability.
            </li>
            <li>
              We may provide various open communication tools on our website, such as blog comments, blog posts, public chat, forums, message boards, newsgroups, product ratings and reviews, various social media services, etc. You understand that generally we do not pre-screen or monitor the content posted by users of these various communication tools, which means that if you choose to use these tools to submit any type of content to our website, then it is your personal responsibility to use these tools in a responsible and ethical manner. By posting information or otherwise using any open communication tools as mentioned, you agree that you will not upload, post, share, or otherwise distribute any content that:
              <ul className="list-disc list-inside pl-4">
                <li>
                  Is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent, deceptive, invasive, racist, or contains any type of suggestive, inappropriate, or explicit language;
                </li>
                <li>
                  Infringes on any trademark, patent, trade secret, copyright, or other proprietary right of any party;
                </li>
                <li>Contains any type of unauthorized or unsolicited advertising;</li>
                <li>Impersonates any person or entity, including any https://yelseva.com/ employees or representatives.</li>
              </ul>
            </li>
          </ul>

          <p className="text-gray-700 mt-4">
            We have the right at our sole discretion to remove any content that, we feel in our judgment does not comply with this User Agreement, along with any content that we feel is otherwise offensive, harmful, objectionable, inaccurate, or violates any 3rd party copyrights or trademarks. We are not responsible for any delay or failure in removing such content. If you post content that we choose to remove, you hereby consent to such removal, and consent to waive any claim against us.
          </p>

          <p className="text-gray-700 mt-4 ">
            We do not assume any liability for any content posted by you or any other 3rd party users of our website. However, any content posted by you using any open communication tools on our website, provided that it doesn't violate or infringe on any 3rd party copyrights or trademarks, becomes the property of https://yelseva.com/, and as such, gives us a perpetual, irrevocable, worldwide, royalty-free, exclusive license to reproduce, modify, adapt, translate, publish, publicly display and/or distribute as we see fit. This only refers and applies to content posted via open communication tools as described, and does not refer to information that is provided as part of the registration process, necessary in order to use our Resources. All information provided as part of our registration process is covered by our Privacy Policy.
          </p>

          <p className="text-gray-700 mt-4">
            You agree to indemnify and hold harmless IndiaFilings.com, a digital property of Yahshua Enterprises and its parent company and affiliates, and their directors, officers, managers, employees, donors, agents, and licensors, from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation of this User Agreement or the failure to fulfill any obligations relating to your account incurred by you or any other person using your account. We reserve the right to take over the exclusive defense of any claim for which we are entitled to indemnification under this User Agreement. In such event, you shall provide us with such cooperation as is reasonably requested by us.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndCondition;
