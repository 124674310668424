import React, { useState, useEffect } from 'react';
import SummaryApi from '../../common/Apis';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner'; // Import TailSpin
import {  toast } from "react-toastify";


const AllUserCertificates = ({ isClosed }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState(''); // Added status filter state
  const [updating, setUpdating] = useState(false); // State to manage loading state during updates
   
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [revertCertificateId, setRevertCertificateId] = useState(null);
    const [issueText, setIssueText] = useState("");
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadCertificateId, setUploadCertificateId] = useState(null);

  const statusOptions = ["", "pending", 'processing', 'approved', 'reverted']; // Added default empty option

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchUserData = async () => {
        try {
          const response = await fetch(SummaryApi.users.url, {
            method: SummaryApi.users.method,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error ${response.status}: ${errorText}`);
          }

          const userData = await response.json();
          setAllUsers(userData);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchUserData();
    } else {
      setError('No token found, please login.');
      setLoading(false);
    }
  }, []);
  const handleRevertClick = (certificateId) => {
    setRevertCertificateId(certificateId);
    setIsModalOpen(true);
  };

  const handleRevertSubmit = async () => {
    if (!issueText.trim()) {
      alert("Please enter a reason for the revert.");
      return;
    }

    try {
      const url = `${SummaryApi.CertificatesRevertHandle.url}/${revertCertificateId}`;
      console.log("Submitting revert with issue:", issueText);

      const response = await axios.put(url, {
        revertReason: issueText,
        selectedDocuments,
      });

      if (response.status === 200) {
        console.log("Revert reason submitted successfully!");
        toast.success("Revert reason submitted successfully!");
        setIssueText("");
        setSelectedDocuments([]);
      }
    } catch (error) {
      console.error("Error reverting the certificate:", error);
      toast.error("Error submitting revert reason.");
    } finally {
      setIsModalOpen(false);
    }
  };
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]); // Support single file upload
  };
  const handleDocumentChange = (selectedOptions) => {
    setSelectedDocuments(selectedOptions || []);
  };


  const filteredUsers = allUsers
    .filter(
      (user) =>
        (user.certificatesApplied && user.certificatesApplied.length > 0) &&
        (user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.certificatesApplied.some(cert =>
            cert._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
            cert.certificateName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            cert.uploadedDocuments.some(doc => doc.filename.toLowerCase().includes(searchTerm.toLowerCase()))
          ))
    )
    .filter((user) => {
      if (!statusFilter) return true; // No filter selected, show all
      return user.certificatesApplied.some(cert => cert.status === statusFilter);
    });

  const handleStatusChange = async (userId, certificateId, newStatus) => {
    const url = `${SummaryApi.users.url}/${userId}/certificates/${certificateId}/status`;
    setUpdating(true);

    try {
      const response = await axios.put(url, { status: newStatus });

      if (response.status === 200) {
        setAllUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId
              ? {
                  ...user,
                  certificatesApplied: user.certificatesApplied.map((cert) =>
                    cert._id === certificateId
                      ? { ...cert, status: newStatus }
                      : cert
                  ),
                }
              : user
          )
        );
        console.log('Status updated successfully!');
      }
    } catch (error) {
      console.error('Error updating certificate status:', error);
    } finally {
      setUpdating(false);
    }
  };
  const handleUploadClick = (certificateId) => {
    setUploadCertificateId(certificateId);
    setIsUploadModalOpen(true);
  };

   // Handle file upload submission
   const handleFileUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("files", selectedFile);
    // formData.append("certificateId", uploadCertificateId);

    try {
      setUploading(true);

      const response = await axios.post(`${SummaryApi.uploadeCompletedCertificate.url}/${uploadCertificateId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        toast.success("File uploaded successfully!");
        setSelectedFile(null);
        setUploadCertificateId(null);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("File upload failed. Please try again.");
    } finally {
      setUploading(false);
      setIsUploadModalOpen(false);
    }
  };


  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100 absolute inset-0 z-50">
        <TailSpin
          visible={true}
          height="80"
          width="100"
          color="#0f0f0f" // Black color
          ariaLabel="tail-spin-loading"
          radius="1"
          animationDuration="0.85"
        />
      </div>
    );
  }

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mt-4">
      <h1 className="text-3xl text-center justify-center font-semibold text-orange-500 pb-3">All Certificates from Each User</h1>
      
      {/* Search and Filter Row */}
      <div className="flex mb-4 space-x-4">
        <input
          type="text"
          placeholder="Search by name, email, certificate ID, certificate name, or document"
          className="px-4 py-2 border-gray-600 border rounded w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="px-4 py-2 border-gray-600 border rounded"
        >
          {statusOptions.map((status) => (
            <option key={status} value={status}>
              {status ? status.charAt(0).toUpperCase() + status.slice(1) : 'All Statuses'}
            </option>
          ))}
        </select>
      </div>

      {/* Certificates Table */}
      <table className="min-w-full border-separate border-spacing-0 border rounded-lg overflow-hidden border-gray-900 mt-5">
        <thead>
          <tr className="rounded-lg">
            <th className="px-4 py-2 border bg-[#075985] text-white rounded-tl-lg">User Name</th>
            <th className="px-4 py-2 border bg-[#075985] text-white">Email</th>
            <th className="px-4 py-2 border bg-[#075985] text-white">Certificate ID</th>
            <th className="px-4 py-2 border bg-[#075985] text-white">Certificate Name</th>
            {/* <th className="px-4 py-2 border bg-[#075985] text-white">Uploaded Documents</th> */}
            <th className="px-4 py-2 border bg-[#075985] text-white">Status</th>
            <th className="px-4 py-2 border bg-[#075985] text-white">Application Date</th>
            <th className="px-4 py-2 border bg-[#075985] text-white rounded-tr-lg">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) =>
              user.certificatesApplied.map((certificate) => (
                <tr key={certificate._id}>
                  <td className="border bg-white px-2 py-1">{user.name}</td>
                  <td className="border bg-white px-2 py-1">{user.email}</td>
                  <td className="border bg-white px-2 py-1">{certificate._id}</td>
                  <td className="border bg-white px-2 py-1">{certificate.certificateName}</td>
                  {/* <td className="border bg-white px-2 py-1">
                    {certificate.uploadedDocuments.map((doc, idx) => (
                      <div key={idx}>
                        {idx + 1}.{' '}
                        <a href={doc.path} target="_blank" rel="noopener noreferrer">
                          {doc.filename}
                        </a>
                      </div>
                    ))}
                  </td> */}
                  <td className="border bg-white px-2 py-1">
                    <select
                      value={certificate.status}
                      onChange={(e) =>
                        handleStatusChange(user._id, certificate._id, e.target.value)
                      }
                      className="border p-2 rounded-md"
                      disabled={updating}
                    >
                      {statusOptions.map((status) => (
                        <option key={status} value={status}>
                          {status.charAt(0).toUpperCase() + status.slice(1)}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="border bg-white px-2 py-1">
                    {new Date(certificate.applicationDate).toLocaleDateString()}
                  </td>
                  <td className=" bg-white border  h-full flex flex-col gap-1 px-4 py-2">
                 
                  <button
                    className="bg-blue-700 text-white px-4 py-1 rounded"
                    onClick={() => handleUploadClick(certificate._id)}
                  >
                    Upload
                  </button></td>
                </tr>
              ))
            )
          ) : (
            <tr>
              <td colSpan="7" className="border px-2 py-1 text-center">
                No users found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {isUploadModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-lg w-full max-w-md mx-4 sm:w-1/2 md:w-1/3">
            <h2 className="text-xl font-semibold mb-4">Upload File</h2>
            <input
              type="file"
              onChange={handleFileChange}
              className="w-full border p-2 rounded-md mb-4"
            />
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => setIsUploadModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleFileUpload}
                disabled={uploading}
              >
                {uploading ? "Uploading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>)}

        
    </div>
  );
};

export default AllUserCertificates;
