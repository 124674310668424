import React from "react";
import Footer from "../Component/Footer/Footer";
import Navbar from "../Component/Navbar/Navbar";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar/>
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-md p-8 mt-10 mb-10">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          Privacy Policy
        </h1>
        <p className="text-gray-700 mb-6">
        This Privacy Policy governs the manner in which yelseva.com collects, uses, maintains and discloses information collected from users (each, a "User") of the https://yelseva.com website ("Site"). This privacy policy applies to the Site and all products and services offered by Yahshua Enterprises.
        </p>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
          PERSONAL IDENTIFICATION INFORMATION
          </h2>
          <p className="text-gray-700">
          We may collect personal identification information from users in a variety of ways, including, but not limited to, when users visit our Site, registers on the Site, places an order, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number, date of birth or incorporation etc. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.
          </p>
         
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
          NON-PERSONAL IDENTIFICATION INFORMATION
          </h2>
          <p className="text-gray-700">
          We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers' utilized and other similar information.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
          WEB BROWSER COOKIES
          </h2>
          <p className="text-gray-700">
          Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
          HOW WE USE COLLECTED INFORMATION
          </h2>
          <ol style={{ listStyleType: "decimal" }}>
    <li>
      To improve customer service: Information provided by users helps us respond to their customer service requests and support needs more efficiently.
    </li>
    <li>
       To personalize user experience:  We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.
    </li>
    <li>
       To improve our Site:  Feedback provided by users is used to improve our products and services.
    </li>
    <li>
       To process payments:  Information provided by users when placing an order is used only to provide service for that order. This information is not shared with outside parties except to the extent necessary to provide the service.
    </li>
    <li>
       To run a promotion, contest, survey, or other Site features:  We use this information to send users details about topics they agreed to receive and that we think will interest them.
    </li>
    <li>
       To send periodic emails:  We use the email address to send users updates about their orders, respond to inquiries, and share other relevant information. Users who opt into our mailing list will receive emails that may include company news, updates, related product information, etc. Detailed unsubscribe instructions are included in every email, or users can contact us via our Site to unsubscribe.
    </li>
  </ol>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
          HOW WE PROTECT YOUR INFORMATION
          </h2>
          <p className="text-gray-700">
          We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.

Sensitive and private data exchange between the Site and its users happens over a SSL secured communication channel and is encrypted and protected with digital signatures. Our Site is also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
          SHARING YOUR PERSONAL INFORMATION
          </h2>
          <p className="text-gray-700">
          We may use third party service providers to help us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided you have given us your consent.

You acknowledge, agree and authorise us to share your personal information with third party service providers who help us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes.

You acknowledge, authorise and agree to collect, store, process and transfer your information to our sister concern entities for the purpose of providing service through this / their platform. You further allow them to communicate with you in any manner including by way of emails, phone calls and messages. If you choose to use services provided by our sister concern, your information may be additionally governed by the privacy policies of such sister concern entity and we will not be responsible for the privacy practices of such sister concern entity.

If we are involved in merger, acquisition, sale, re-organisation, joint venture, transfer or disposition of all or any portion of our business, we will continue to ensure confidentiality of user information and will give prior intimation to affected users in case sharing of information is required.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Link to Other Sites
          </h2>
          <p className="text-gray-700">
          Our platform provides link to other Sites. Such Sites may collect user information. We recommend user to review privacy policy of such Site as we are not responsible for their privacy practices.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
          GOOGLE ADSENSE
          </h2>
          <p className="text-gray-700">
          Some of the ads may be served by Google. Google's use of the DART cookie enables it to serve ads to users based on their visit to our Site and other Sites on the Internet. DART uses "non personally identifiable information" and does NOT track personal information about you, such as your name, email address, physical address, etc. You may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy at http://www.google.com/
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
          COMPLIANCE WITH CHILDREN'S ONLINE PRIVACY PROTECTION ACT
          </h2>
          <p className="text-gray-700">
          Protecting the privacy of the young is especially important. For that reason, we never collect or maintain information at our Site from those we know are under 13years, and no part of our Site is structured to attract anyone under 13.          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
          CHANGES TO THIS PRIVACY POLICY
          </h2>
          <p className="text-gray-700">
          YEL Seva have the discretion to update this privacy policy at any time. When we do, we will send you an email. We encourage users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information collected. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications. The continued use of our platform and our services will denote your acceptance of our modified policy and will be adequate proof that you have agreed to the terms of this policy which shall apply from the first day of your use of the platform. If changes are significant, we will provide more prominent notice.          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Contact Us
          </h2>
          <p className="text-gray-700">
          If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:
          Yahshua Enterprises Shop No.01, Opposite Rajmudra Petrol Pump, Marunji Road, Marunji Gaon, Hinjewadi Phase 1, Pune,India 411057{" "}
            <a
              href="mailto:Yahshuaenterprises@gmail.com"
              className="text-blue-600 underline"
            >
             Yahshuaenterprises@gmail.com
            </a>
            .
          </p>
        </section>
       
      </div>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicy;