import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Fix import for jwt-decode

import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineLoading } from "react-icons/ai"; // Icon for loading spinner
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify styles
import SummaryApi from "../common/Apis"; // Assuming you have this in place
import { Usercontext } from "../Store/UserContext";
import UserNavbar from "../Component/Navbar/UserNavbar";

const UploadServices = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, fetchUserData } = useContext(Usercontext);

  const { certificatename } = location.state || {}; // Fallback in case state is undefined
  const [certificateName, setCertificateName] = useState(certificatename || ""); // Fallback for certificate name
  const [originalAmount, setOriginalAmount] = useState();
  const [proofOfIdentity, setProofOfIdentity] = useState([""]);
  const [proofOfAddress, setProofOfAddress] = useState([""]);
  const [availableIdentityDocs, setAvailableIdentityDocs] = useState([]);
  const [availableAddressDocs, setAvailableAddressDocs] = useState([]);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [payAmount, setAmount] = useState(0);
  const [requiredAddressDocs, setRequiredAddressDocs] = useState();
  const [requiredIdentityDocs, setRequiredIdentityDocs] = useState();
  const [otherDocuments, setOtherDocuments] = useState([""]);
  const [ageProof, setAgeProof] = useState([""]);
  const [proofOfIncome, setProofOfIncome] = useState([""]);
  const [passportSizedPhotograph, setPassportSizedPhotograph] = useState([""]);
  const [mandatoryDocuments, setmandatoryDocuments] = useState([""]);
  const [availablemandatoryDocuments, setavailablemandatoryDocuments] =
    useState([]);
  const [availableOtherDocuments, setavailableOtherDocuments] = useState([]);
  const [availableAgeProof, setavailableAgeProof] = useState([]);
  const [availableProofOfIncome, setavailableProofOfIncome] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);

  const handlePreview = (imageSrc) => {
    setPreviewImage(imageSrc);
    console.log(imageSrc)
  };

  const [
    availablePassportSizedPhotograph,
    setavailablePassportSizedPhotograph,
  ] = useState([]);
  // New States for form
  const [formFor, setFormFor] = useState("self"); // Dropdown state
  const [personName, setPersonName] = useState(user?.name || "");
  const [mobileNumber, setMobileNumber] = useState(user?.phoneNo || "");
  const [Address, setAddress] = useState(user?.address || "");
  const [showModal, setShowModal] = useState(false);
  const [userFormdata, setUserFormData] = useState({
    certificateName: "",
    formFor: "",
    personName: "",
    mobileNumber: "",
    Address: "",
    proofOfIdentity: "",
    otherDocuments: "",
    ageProof: "",
    proofOfIncome: "",
    passportSizedPhotograph: "",
    mandatoryDocuments: "",
    proofOfAddress: "",
  });
  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
  };
  const handleCertificateClick = () => {
    setUserFormData({
      certificateName,
      formFor,
      personName,
      mobileNumber,
      Address,
      proofOfIdentity,
      otherDocuments,
      ageProof,
      proofOfIncome,
      passportSizedPhotograph,
      mandatoryDocuments,
      proofOfAddress,
    });
    setShowModal(true); // Show the modal
  };

  // Extract user ID from JWT token
  useEffect(() => {
    const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken._id); // Set the user ID from the decoded token
    }
  }, []);

  useEffect(() => {
    if (formFor === "self") {
      setPersonName(user?.name || "");
      setMobileNumber(user?.phoneNo || "");
      setAddress(user?.address || "");
    } else {
      setPersonName("");
      setMobileNumber("");
      setAddress("");
    }
  }, [formFor, user]);
  // Fetch proof of identity and address documents from backend on component load
  useEffect(() => {
    const url = `${SummaryApi.documents.url}/${encodeURIComponent(
      certificateName
    )}`;

    const fetchDocuments = async () => {
      try {
        const response = await axios.get(url);
        const {
          proofOfIdentity,
          proofOfAddress,
          amount,
          proofOfAddressRequiredCount,
          proofOfIdentityRequiredCount,
          mandatoryDocuments,
          otherDocuments,
          ageProof,
          proofOfIncome,
          passportSizedPhotograph,
        } = response.data; // Adjust based on response structure
        setRequiredAddressDocs(proofOfAddressRequiredCount);
        setRequiredIdentityDocs(proofOfIdentityRequiredCount);
        setAmount(calculateGST(amount));
        setOriginalAmount(amount);
        setAvailableIdentityDocs(proofOfIdentity);
        setAvailableAddressDocs(proofOfAddress);
        setavailableOtherDocuments(otherDocuments);
        setavailablePassportSizedPhotograph(passportSizedPhotograph);
        setavailableAgeProof(ageProof);
        setavailableProofOfIncome(proofOfIncome);
        setavailablemandatoryDocuments(mandatoryDocuments);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    if (certificateName) {
      fetchDocuments();
    }
  }, [certificateName]);

  const handleFileUpload = (e, fieldType) => {
    const files = Array.from(e.target.files); // Convert FileList to an array

    const imgArray = files.map((file) => {
      return URL.createObjectURL(file);
    });

    console.log(imgArray);

    // Map field types to their respective state setters and current state
    const fieldMapping = {
      identity: [proofOfIdentity, setProofOfIdentity],
      address: [proofOfAddress, setProofOfAddress],
      otherDocuments: [otherDocuments, setOtherDocuments],
      ageProof: [ageProof, setAgeProof],
      proofOfIncome: [proofOfIncome, setProofOfIncome],
      passportSizedPhotograph: [
        passportSizedPhotograph,
        setPassportSizedPhotograph,
      ],
      mandatoryDocuments: [mandatoryDocuments, setmandatoryDocuments],
    };

    // Check if the fieldType is valid
    if (fieldMapping[fieldType]) {
      const [currentState, setState] = fieldMapping[fieldType]; // Destructure the state and setter
      const updatedFiles = [...currentState, ...files]; // Append new files to the existing state
      setState(updatedFiles); // Update the state with the new array
    } else {
      console.error(`Invalid fieldType: ${fieldType}`);
    }
  };

  function calculateGST(amount) {
    const gstRate = 0.18;
    const gstAmount = amount * gstRate;
    const totalAmount = amount + gstAmount;
    return totalAmount;
  }

  // Handle Payment
  const handlePayment = async () => {
    try {
      const url = SummaryApi.payment.url;
      const paymentResponse = await axios.post(url, {
        amount: payAmount,
      });

      const { amount, id: order_id, currency } = paymentResponse.data;

      if (typeof window.Razorpay === "undefined") {
        console.error("Razorpay SDK not loaded");
        toast.error("Payment gateway not available");
        return;
      }

      const options = {
        key: "rzp_test_U4XuiM2cjeWzma",
        amount: amount,
        currency: currency,
        name: "Certificate Service",
        description: "Payment for certificate",
        order_id: order_id,
        handler: async (response) => {
          try {
            const paymentId = response.razorpay_payment_id;
            console.log("Payment successful:", paymentId);
            await handleSubmit();
          } catch (error) {
            console.error("Error during payment handling:", error);
          }
        },
        theme: { color: "#3399cc" },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
      toast.error("Error initiating payment");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("certificateName", certificateName);
    formData.append("formFor", formFor);
    formData.append("name", personName);
    formData.append("phoneNo", mobileNumber);
    formData.append("address", Address);
    // Append proof of identity files
    proofOfIdentity.forEach((file) => {
      if (file) {
        formData.append("proofOfIdentity", file);
      }
    });

    // Append other documents
    otherDocuments.forEach((file) => {
      if (file) {
        formData.append("otherDocuments", file); // Corrected field name
      }
    });

    // Append age proof files
    ageProof.forEach((file) => {
      if (file) {
        formData.append("ageProof", file); // Corrected field name
      }
    });

    // Append proof of income files
    proofOfIncome.forEach((file) => {
      if (file) {
        formData.append("proofOfIncome", file); // Corrected field name
      }
    });

    // Append passport sized photograph files
    passportSizedPhotograph.forEach((file) => {
      if (file) {
        formData.append("passportSizedPhotograph", file); // Corrected field name
      }
    });

    // Append mandatory documents files
    mandatoryDocuments.forEach((file) => {
      if (file) {
        formData.append("mandatoryDocuments", file); // Corrected field name
      }
    });

    // Append proof of address files
    proofOfAddress.forEach((file) => {
      if (file) {
        formData.append("proofOfAddress", file);
      }
    });

    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
    setUserFormData({
      certificateName,
      formFor,
      personName,
      mobileNumber,
      Address,
      proofOfIdentity,
      otherDocuments,
      ageProof,
      proofOfIncome,
      passportSizedPhotograph,
      mandatoryDocuments,
      proofOfAddress,
    });
    console.log("userFormdata", userFormdata);

    try {
      const token = localStorage.getItem("token");
      const url = `${SummaryApi.users.url}/${userId}/certificates`;
      await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      navigate("/dashbord");
      toast.success("Certificate details uploaded successfully");

      setTimeout(() => {}, 2000);
      fetchUserData();
    } catch (error) {
      toast.error("Error uploading certificate details");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const documentSections = [
    {
      title: "Proof of Identity",
      key: "identity",
      docs: availableIdentityDocs,
      requiredDocs: requiredIdentityDocs,
    },
    {
      title: "Proof of Income",
      key: "proofOfIncome",
      docs: availableProofOfIncome,
      requiredDocs: requiredIdentityDocs,
    },
    {
      title: "Proof of Address",
      key: "address",
      docs: availableAddressDocs,
      requiredDocs: requiredAddressDocs,
    },
    {
      title: "Proof of Age",
      key: "ageProof",
      docs: availableAgeProof,
      requiredDocs: requiredAddressDocs,
    },
    {
      title: "Passport Sized Photograph",
      key: "passportSizedPhotograph",
      docs: availablePassportSizedPhotograph,
      requiredDocs: requiredAddressDocs,
    },
    {
      title: "Mandatory Documents",
      key: "mandatoryDocuments",
      docs: availablemandatoryDocuments,
      requiredDocs: requiredAddressDocs,
    },
    {
      title: "Other Documents",
      key: "otherDocuments",
      docs: availableOtherDocuments,
      requiredDocs: requiredIdentityDocs,
    },
  ];

  return (
    <>
      <UserNavbar />
      {/* <div className="lg:ml-14 mt-5 lg:mt-0">
        {user.role === "customer" && (
          <button
            className="bg-blue-700 text-white rounded-lg p-2 m-1 border"
            onClick={handleClick}
          >
            Go to Services List
          </button>
        )}
      </div> */}
      <div className="w-full lg:w-[70%] mx-auto p-4 lg:p-8  bg-white shadow-xl shadow-gray-400 mt-6 lg:mt-4 mb-10 rounded-md">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Add Certificate Details
        </h2>
        <form className="space-y-4">
          <table className="table-auto w-full border-collapse border border-gray-300">
            <tbody>
              {/* Certificate Name Row */}
              <tr>
                <td className="px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 w-1/4">
                  Certificate Name <span className="text-red-500">*</span>
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  <input
                    value={certificateName}
                    className="p-2 block w-full border-2 border-gray-400 rounded-md"
                    placeholder="Enter certificate name"
                    required
                  />
                </td>
              </tr>
              {/* Filling Form For */}
              <tr>
                <td className="px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700">
                  Filling Form For <span className="text-red-500">*</span>
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  <select
                    value={formFor}
                    onChange={(e) => setFormFor(e.target.value)}
                    className="p-2 block w-full border-2 border-gray-400 rounded-md"
                  >
                    <option value="self">Self</option>
                    <option value="son">Son</option>
                    <option value="daughter">Daughter</option>
                    <option value="wife">Wife</option>
                    <option value="other">Others</option>
                  </select>
                </td>
              </tr>
              {/* Name and Mobile Number */}
              {formFor && (
                <>
                  <tr>
                    <td className="px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700">
                      Name <span className="text-red-500">*</span>
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      <input
                        type="text"
                        value={personName}
                        onChange={(e) => setPersonName(e.target.value)}
                        className="p-2 block w-full border-2 border-gray-400 rounded-md"
                        placeholder="Enter name"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700">
                      Mobile Number <span className="text-red-500">*</span>
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      <input
                        type="text"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        className="p-2 block w-full border-2 border-gray-400 rounded-md"
                        placeholder="Enter mobile number"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700">
                      Address <span className="text-red-500">*</span>
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      <textarea
                        rows="2"
                        value={Address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="p-2 block w-full border-2 border-gray-400 rounded-md"
                        placeholder="Enter Address Here"
                        required
                      ></textarea>
                    </td>
                  </tr>
                </>
              )}
              {documentSections.map(
                ({ title, key, docs, requiredDocs }) =>
                  docs &&
                  docs.length > 0 && (
                    <tr key={key}>
                      <td className="px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700">
                        {title}{" "}
                        <span className="text-red-500">
                          * any {requiredDocs}
                        </span>
                      </td>
                      <td className="px-4 py-2 border border-gray-300">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                          {docs.map((doc, docIndex) => (
                            <div key={docIndex} className="mb-4">
                              <h3 className="text-sm">{doc}</h3>
                              <input
                                type="file"
                                multiple
                                className="mt-2 border border-gray-400 rounded-md text-sm lg:text-base w-full max-w-sm"
                                onChange={(e) =>
                                  handleFileUpload(e, key, docIndex)
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </td>
                    </tr>
                  )
              )}{" "}
            </tbody>
          </table>

          <div className="flex justify-between items-center text-sm text-gray-700 font-medium mb-4">
            <span className="flex-1">Price:</span>
            <span className="text-right">₹{originalAmount}</span>
          </div>

          <div className="flex justify-between items-center text-sm text-gray-700 font-medium mb-4">
            <span className="flex-1">GST (18%):</span>
            <span className="text-right">
              ₹{(originalAmount * 0.18).toFixed(2)}
            </span>
          </div>
          {/* Total Amount */}
          <div className="flex justify-between items-center text-sm text-gray-900 font-bold mb-4">
            <span className="flex-1">Total Amount:</span>
            <span className="text-right">
              ₹{(originalAmount + originalAmount * 0.18).toFixed(2)}
            </span>
          </div>

          {/* Show all Form data Provided By User */}
          {showModal && (
            <div className="fixed inset-0 flex items-start justify-center z-50 bg-black bg-opacity-60 ">
              <div className="bg-white rounded-xl shadow-xl w-11/12 sm:w-10/12 md:w-2/3 max-h-[90vh] overflow-auto relative transition-all transform scale-90">
                {/* Close Button */}
                <button
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-800 transition duration-200"
                  onClick={handleCloseModal}
                >
                  ✖
                </button>

                <div className="p-7">
                  {/* Modal Header */}
                  <h4 className="text-3xl md:text-3xl font-semibold text-gray-800 text-center mb-6">
                    Confirm Your Details
                  </h4>

                  <div className="flex flex-col lg:flex-row gap-6">
                    {/* Modal Content Section */}
                    <div
                      className="flex-1 bg-gray-200  overflow-auto rounded-lg p-6 shadow-sm "
                      style={{ maxHeight: "80vh" }}
                    >
                      <ul className="space-y-4">
                        {userFormdata &&
                        Object.keys(userFormdata).length > 0 ? (
                          Object.entries(userFormdata).map(([key, value]) => (
                            <li key={key} className="flex flex-col mb-4">
                              <span className="font-medium text-gray-700 capitalize mb-2">
                                {key.replace(/([A-Z])/g, " $1")}:
                              </span>
                              <div className="text-gray-600">
                                {Array.isArray(value) ? (
                                  value.length > 0 ? (
                                    value.map((file, index) => (
                                      <div
                                        key={`${key}-${index}`}
                                        className="flex items-center gap-2 mb-2"
                                      >
                                        {/* File Name */}
                                        <span>{file.name}</span>

                                        {/* Preview Icon */}
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handlePreview(
                                              file instanceof File
                                                ? URL.createObjectURL(file)
                                                : file
                                            )
                                          }
                                          className="text-blue-500 hover:text-blue-700 transition duration-200"
                                        >
                                          {file.name ? "🔍" : ""}
                                        </button>
                                      </div>
                                    ))
                                  ) : (
                                    <span className="italic text-gray-400">
                                      None
                                    </span>
                                  )
                                ) : typeof value === "object" &&
                                  value !== null ? (
                                  JSON.stringify(value)
                                ) : (
                                  value || (
                                    <span className="italic text-gray-400">
                                      N/A
                                    </span>
                                  )
                                )}
                              </div>
                            </li>
                          ))
                        ) : (
                          <li className="text-center text-gray-500 italic">
                            No data available
                          </li>
                        )}
                      </ul>
                    </div>

                    {/* Price Section */}
                    <div className="w-full lg:w-1/3 bg-gray-200 rounded-lg p-6 shadow-sm flex flex-col justify-between">
                      {/* Price Details */}
                      <div className="space-y-4">
                        <div className="flex justify-between text-gray-700 font-medium">
                          <span>Price:</span>
                          <span>₹{originalAmount}</span>
                        </div>
                        <div className="flex justify-between text-gray-700 font-medium">
                          <span>GST (18%):</span>
                          <span>₹{(originalAmount * 0.18).toFixed(2)}</span>
                        </div>
                        <div className="flex justify-between text-gray-900 font-semibold text-lg">
                          <span>Total Amount:</span>
                          <span>₹{payAmount}</span>
                        </div>
                      </div>

                      {/* Submit Button */}
                      <button
                        type="button"
                        onClick={handlePayment}
                        className="mt-6 bg-blue-600 hover:bg-blue-700 transition duration-200 text-white font-semibold text-lg rounded-lg py-3 flex justify-center items-center"
                      >
                        {loading ? (
                          <div className="flex items-center gap-2">
                            <AiOutlineLoading className="animate-spin text-white text-xl" />
                            <span>Processing...</span>
                          </div>
                        ) : (
                          "Submit Details and Pay"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Image Preview Modal */}
          {previewImage && (
            <div className="fixed inset-0 flex items-start  bg-black bg-opacity-60 justify-center z-50 ">
              <div
                className="bg-white p-4 mt-3  rounded-lg shadow-lg relative"
                style={{ minHeight: "90vh" }}
              >
                {/* Close Button */}
                <button
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
                  onClick={() => setPreviewImage(null)}
                >
                  ✖
                </button>

                {/* Image */}
                <img
                  src={previewImage}
                  alt="Preview"
                  className="max-w-full max-h-[80vh] rounded-lg"
                />
              </div>
            </div>
          )}

          <div className="flex justify-center items-center ">
            <button
              type="button"
              // onClick={handleSubmit}
              onClick={handleCertificateClick}
              className="bg-blue-700 w-full text-white rounded-lg p-2"
            >
              <div>Proceed</div>
            </button>
          </div>
        </form>
      </div>

      <ToastContainer />
    </>
  );
};

export default UploadServices;
