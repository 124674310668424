import React from "react";
import { Outlet } from "react-router-dom";
import UserNavbar from "../Component/Navbar/UserNavbar";
import SuperAdminSidebar from "../Component/SuperAdmin/SuperAdminSidebar";
import Footer from "../Component/Footer/Footer";



const SuperAdmin = () => {
  return (
    <>
      <div className="flex flex-col h-screen">
        {/* Fixed Navbar */}
        <div className="fixed top-0 left-0 w-full z-10">
          <UserNavbar />
        </div>

        {/* Sidebar and Content Area */}
        <div className=" flex flex-col mt-16 "> {/* Push content down under the navbar */}
          {/* Fixed Sidebar */}
          <div className="flex h-screen">
          <div className=" left-0 "> {/* 64px height reserved for the navbar */}
            <SuperAdminSidebar/>
          </div>

          {/* Main Content */}
          <div className=" bg-gray-300   w-full overflow-auto" >
            <Outlet/>
            </div>
          </div>
        </div>

        {/* Footer */}
        {/* <div className="fixed">
        <Footer />
        </div> */}
        <footer className="bg-gray-200 py-2  w-full   h-full bottom-0 top-0 ">
          <Footer />
        </footer>
         
        
      </div>
    </>
  );
};

export default SuperAdmin;
