import React from 'react'
import Navbar from '../Component/Navbar/Navbar';
import Footer from '../Component/Footer/Footer';

const ShippingDelivery = () => {
    return (
        <div className="min-h-screen bg-gray-100 ">
             <Navbar/>
          <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-md p-8 mt-10 mb-10">
            <h1 className="text-3xl font-bold text-gray-800 mb-6">
            Shipping and Delivery Terms
            </h1>
           
    
            <section className="mb-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Privacy
              </h2>
              <p className="text-gray-700">
              Your privacy is very important to us, which is why we've created a separate Privacy Policy in order to explain in detail how we collect, manage, process, secure, and store your private information. Our privacy policy is included under the scope of this User Agreement. To read our privacy policy in its entirety, click here.

              </p>
             
            </section>
    
            <section className="mb-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
              LIMITATION OF WARRANTIES

              </h2>
              <p className="text-gray-700">
              By using our website, you understand and agree that all Resources we provide are "as is" and "as available". This means that we do not represent or warrant to you that:

              </p>
            </section>
    
            <section className="mb-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
              LIMITATION OF LIABILITY

              </h2>
              <p className="text-gray-700">
              In conjunction with the Limitation of Warranties as explained above, you expressly understand and agree that any claim against us shall be limited to the amount you paid, if any, for use of products and/or services. https://yelseva.com will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you as a result of using our Resources, or as a result of any changes, data loss or corruption, cancellation, loss of access, or downtime to the full extent that applicable limitation of liability laws apply.

              </p>
            </section>
    
            <section className="mb-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
              COPYRIGHTS / TRADEMARKS

              </h2>
              <p className="text-gray-700">
              All content and materials available on https://yelseva.com/, including but not limited to text, graphics, website name, code, images and logos are the intellectual property of Yahshua Enterprises, and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission of any content on this site is strictly prohibited, unless specifically authorized by Yahshua Enterprises.

              </p>
            </section>
    
            <section className="mb-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
              TERMINATION OF USE

              </h2>
              <p className="text-gray-700">
              You agree that we may, at our sole discretion, suspend or terminate your access to all or part of our website and Resources with or without notice and for any reason, including, without limitation, breach of this User Agreement. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities. Upon suspension or termination, your right to use the Resources we provide will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information.

              </p>
            </section>
    
            <section className="mb-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
              GOVERNING LAW

              </h2>
              <p className="text-gray-700">
              This website is controlled by Yahshua Enterprises from our offices located in the state of Maharashtra, India. It can be accessed by most countries around the world. As each country has laws that may differ from those of Maharashtra, India by accessing our website, you agree that the statutes and laws of Maharashtra, India without regard to the conflict of laws and the United Nations Convention on the International Sales of Goods, will apply to all matters relating to the use of this website and the purchase of any products or services through this site.
Furthermore, any action to enforce this User Agreement shall be brought in the federal or state courts located in Maharashtra, India. You hereby agree to personal jurisdiction by such courts, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.

              </p>
            </section>
    
            <section className="mb-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
              CANCELLATION AND REFUND

              </h2>
              <p className="text-gray-700">
              Cancellation of order is not possible once the payment has been made. No refunds will be given except in the event of cancellation or non-performance of service by Yahshua Enterprises
              </p>
            </section>
    
            <section className="mb-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
              GUARANTEE

              </h2>
              <p className="text-gray-700">
              UNLESS OTHERWISE EXPRESSED, https://yelseva.com/ EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.

              </p>
            </section>
    
            <section className="mb-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                Contact Us
              </h2>
              <p className="text-gray-700">
              If you have any questions or comments about these our Terms of Service as outlined above, you can contact us at: Yahshua Enterprises
Yahshua Enterprises
Shop No.01, Opposite Rajmudra Petrol Pump, Marunji Road, Marunji Gaon, Pune 411057 India
<br></br>Phone : +91 8850115960.{" "}
<a
              href="mailto:Yahshuaenterprises@gmail.com"
              className="text-blue-600 underline"
            >
             Yahshuaenterprises@gmail.com
            </a>
                .
              </p>
            </section>
          </div>
          <Footer/>
        </div>
      );

}

export default ShippingDelivery

